import {
  Box,
  ButtonBase,
  Card,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';
import Image from 'next/image';

export default function IconActionButton({
  id,
  src,
  label,
  onClick,
  selected,
  sx,
  'data-testid': testId
}: {
  id?: string | undefined;
  src: string;
  label: string;
  onClick?: () => void | undefined;
  selected?: boolean | undefined;
  sx?: SxProps<Theme> | undefined;
  variant?: 'small' | 'large';
  height?: number | undefined;
  width?: number | undefined;
  'data-testid'?: string | undefined;
}) {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const getIconWidth = () => {
    if (matchesMd) {
      return 100;
    }
    if (matchesXs) {
      return 60;
    }
    return 80;
  };

  const asPixels = (n: number | undefined): string | undefined =>
    n !== undefined ? `${n}px` : undefined;

  return (
    <ButtonBase id={id} onClick={onClick} data-testid={testId}>
      <Card
        sx={{
          width: '100%',
          aspectRatio: matchesMd ? '1 / 1' : undefined,
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          textAlign: 'center',
          borderRadius: '0.5rem',
          border: '2px solid #689E41',
          boxShadow: 'none',
          backgroundColor:
            selected !== undefined && selected
              ? 'rgba(104, 158, 65, 0.2)'
              : undefined,
          ':hover': {
            backgroundColor: 'rgba(104, 158, 65, 0.2)'
          },
          ...sx
        }}
      >
        <Grid container alignItems="center" minWidth="100%" minHeight="100%">
          <Grid item xs={4} md={12} minHeight="100%">
            <Box
              sx={{
                top: 0,
                bottom: 0,
                position: 'relative',
                minWidth: asPixels(getIconWidth()),
                minHeight: '100px'
              }}
            >
              <Image
                src={src}
                alt={label}
                fill
                style={{ objectFit: 'contain' }}
              />
            </Box>
          </Grid>
          <Grid item xs={8} md={12} justifySelf={matchesMd ? 'end' : undefined}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </ButtonBase>
  );
}
