'use client';
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import IconActionButton from '@/app/(trailhead)/components/iconActionButton';
import { useRouter } from 'next/navigation';

export default function Page() {
  const doCalc = (x: number) => {
    const result = x * 2;
    console.log(result);
    throw new Error('Test Error');
  };
  return (
    <Box
      display="flex"
      sx={{
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        pt: 10,
        pb: 10
      }}
    >
      <Button
        id="datadog-test"
        sx={{
          display: 'none'
        }}
        variant="contained"
        onClick={() => {
          // some code to check stack tracie
          console.log('testing Datadog Stack Tracing');
          doCalc(10);
        }}
      >
        Test Datadog
      </Button>
      <Content />
    </Box>
  );
}

function Content() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  const getMinWidth = () => {
    if (matchesMd) {
      return '150px';
    }
    if (matchesXs) {
      return '170px';
    }
    return '200px';
  };

  const getMaxHeight = () => {
    if (matchesMd) {
      return undefined;
    }
    if (matchesXs) {
      return '60px';
    }
    return '80px';
  };

  const getMaxWidth = () => {
    if (matchesMd) {
      return '150px';
    }
    if (matchesXs) {
      return '100px';
    }
    return '300px';
  };

  return (
    <Card
      sx={{
        display: 'inline-block',
        p: '40px',
        maxWidth: matchesMd ? '600px' : '400px',
        borderRadius: '10px'
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        columnSpacing="20px"
        rowSpacing="20px"
        minWidth="100%"
      >
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center" mb="20px">
            What would you like installed?
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} textAlign={matchesMd ? 'right' : 'center'}>
          <IconActionButton
            data-testid="ev-charger-button"
            src="/charger-car.svg"
            sx={{
              minWidth: getMinWidth(),
              maxWidth: getMaxWidth(),
              maxHeight: getMaxHeight()
            }}
            label="EV Charger"
            onClick={() => {
              router.push('/charger/zip-code');
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} textAlign={matchesMd ? 'left' : 'center'}>
          <IconActionButton
            data-testid="battery-solar-button"
            src="/battery-icon.svg"
            label="Battery"
            sx={{
              minWidth: getMinWidth(),
              maxWidth: getMaxWidth(),
              maxHeight: getMaxHeight()
            }}
            onClick={() => {
              router.push('/battery');
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
